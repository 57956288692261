import moment from 'moment';

const flatDeep = function (arr, d = 1) {
    return d > 0
        ? arr.reduce((acc, val) => acc.concat(Array.isArray(val) ? flatDeep(val, d - 1) : val), [])
        : arr.slice();
};

const slugify = function (text = '') {
    return text
        .toString()
        .toLowerCase()
        .replace(/\s+/g, '-') // Replace spaces with -
        .replace(/[^\w-]+/g, '') // Remove all non-word chars
        .replace(/--+/g, '-') // Replace multiple - with single -
        .replace(/^-+/, '') // Trim - from start of text
        .replace(/-+$/, ''); // Trim - from end of text
};

const containsObject = function (obj, list) {
    var i;
    for (i = 0; i < list.length; i++) {
        if (list[i].slug === obj.slug) {
            return i;
        }
    }
    return -1;
};

const formatPrice = (number) => {
    return new Intl.NumberFormat('en-US', {
        style: 'currency',
        currency: 'USD',
    }).format(number);
};

const getUniqueValues = (data, type, withAll = 'active') => {
    let unique = data.map((item) => item[type]);
    if (withAll === 'active') {
        return ['all', ...new Set(unique)];
    } else {
        return [...new Set(unique)];
    }
};

const getUniqueValues2 = (data) => {
    const items = data.map((item) => {
        return item.itemName;
    });

    let singleCatArray = flatDeep(items);
    let itemArray = [];
    singleCatArray.forEach((cat) => {
        const obj = {
            title: cat.trim(),
            slug: slugify(cat),
            count: 1,
        };
        const objIndex = containsObject(obj, itemArray);
        if (objIndex !== -1) {
            const prevCount = itemArray[objIndex].count;
            itemArray[objIndex] = {
                title: cat.trim(),
                slug: slugify(cat),
                count: prevCount + 1,
            };
        } else {
            itemArray.push(obj);
        }
    });
};

const getDatesBetween = (startDate, endDate, includeEndDate) => {
    const dates = [];
    const currentDate = new Date(startDate);
    while (currentDate < new Date(endDate)) {
        dates.push(new Date(currentDate));
        currentDate.setDate(currentDate.getDate() + 1);
    }
    if (includeEndDate) dates.push(new Date(endDate));
    return dates;
};

export const getWorkshopDaysAndWeeks = (sDate, eDate) => {
    const datesLength = getDatesBetween(sDate, eDate, true);

    return parseInt(datesLength.length / 7, 10) === 0
        ? String(Math.round(datesLength.length % 7)) + `${datesLength.length % 7 === 1 ? ' Day' : ' Days'}`
        : String(Math.round(datesLength.length / 7)) + ' Week';
};

export const getDaysOrWeek = (days) => {
    return parseInt(days / 7, 10) === 0
        ? String(Math.round(days % 7)) + `${days % 7 === 1 ? ' Day' : ' Days'}`
        : String(Math.round(days / 7)) + ' Week';
};

export const getWorkshopDates = (sDate, eDate) => {
    const isValid = moment(sDate).format('YYYY-DD-MM').toString().toLowerCase() === 'invalid date' ? false : true;
    const startDate = moment(sDate).format('Do MMM');
    const endDate = moment(eDate).format('Do MMM');
    const year = moment(eDate).format('YYYY');
    const isSame = `${startDate + year}` === `${endDate + year}`;
    if (isValid) return isSame ? `${startDate + ' ' + year}` : `${startDate} - ${endDate} ${year}`;
    else {
        return 'Coming soon';
    }
};

// eslint-disable-next-line no-unused-vars
const getPaymentUrl = (name, batchId, url, uniqueId = null, medium = null, params = '') => {
    const url_name = url !== undefined ? String(url).toLowerCase() : slugify(name);
    if (uniqueId) return `/payments/${url_name}?id=${batchId}&discountId=${uniqueId}`;
    return `/payments/${url_name}?id=${batchId}${params ? params : ''}`;
};

const getMasterclassUrl = (name, batchId, url) => {
    return `/masterclass/${url !== undefined ? String(url).toLowerCase() : slugify(name)}?id=${batchId}`;
};

const getCourseUrl = (name, batchId, url) => {
    return `/course/${url !== undefined ? url : slugify(name)}?id=${batchId}`;
};

export const getWorkshopURL = (type, name, wsId, url, params = null) => {
    return `${type == 1 ? '/masterclass/' : '/course/'}${url !== undefined ? url : slugify(name)}?id=${wsId}${params ? params : ''}`;
};

const getShortTitle = (shortTitleType, shortTitle, name, type) => {
    if (shortTitleType && shortTitleType.length > 0) {
        const _shortTitleType = Array.from(shortTitleType);
        if (_shortTitleType.find((item) => item === type)) {
            if (shortTitle) {
                return shortTitle;
            } else {
                return name;
            }
        } else {
            return name;
        }
    } else {
        return name;
    }
};

const getBlogDetailsURL = (title, category, id, url) => {
    return `/blog/${slugify(category)}/${url ? url : slugify(title)}?id=${id}`;
};

function youtube_id_parser(url) {
    var regExp = /^.*((youtu.be\/)|(v\/)|(\/u\/\w\/)|(embed\/)|(watch\?))\??v?=?([^#&?]*).*/;
    var match = url.match(regExp);
    return match && match[7].length == 11 ? match[7] : false;
}

function getInstaPostId(postUrl) {
    // var userURL = 'https://www.instagram.com/p/CBnwdW5n2VA/';
    // var userURL1 = 'https://www.instagram.com/angelinajolie_offiicial/p/CBnwdW5n2VA/';

    var regExp = /\/p\/(.*?)\//;

    var match = postUrl.match(regExp);
    // var match1 = userURL.match(regExp);

    return match[1];
}

const getPricing = (_currency, _rupee, _dollar, showSlash = true) => {
    if (!_rupee) {
        return `${_currency === 'INR' ? '₹' : '$'}`
    }
    if (!_dollar) {
        return `₹${_rupee}${showSlash ? '/-' : ''}`;
    }
    return `${_currency === 'INR' ? '₹' : '$'}${_currency === 'INR' ? _rupee || '' : _dollar || ''}${_currency === 'INR' && showSlash ? '/-' : ''
        }`;
};

const remove_duplicates = (_array = []) => {
    const uniqueArray = [];
    if (!_array.length) return;
    _array.forEach((obj) => {
        if (!uniqueArray.some((item) => item?.id === obj?.id)) {
            uniqueArray.push(obj);
        }
    });

    return uniqueArray;
};

const removeDuplicates = (_array, key) => {
    const uniqueArray = [];
    _array.forEach((obj) => {
        if (!uniqueArray.some((item) => item[`${key}`] === obj[`${key}`])) {
            uniqueArray.push(obj);
        }
    });

    return uniqueArray;
};

function replaceLinksWithAnchorTags(str) {
    const linkRegex = /(?:https?|ftp):\/\/[\S]+/gi;
    const anchorTagTemplate = '<a href="$&" target="_blank">$&</a>';
    return str.replace(linkRegex, anchorTagTemplate);
}

function getGreeting() {
    var currentTime = new Date();
    var currentHour = currentTime.getHours();
    var greeting;

    if (currentHour >= 0 && currentHour < 12) {
        greeting = 'Good Morning';
    } else if (currentHour >= 12 && currentHour < 17) {
        greeting = 'Good Afternoon';
    } else {
        greeting = 'Good Evening';
    }

    return greeting;
}

function convertToBoldItalic(text) {
    const boldText = text.replace(/\*([^*]+)\*/g, '<strong>$1</strong>');
    const italicText = boldText.replace(/_([^_]+)_/g, '<em>$1</em>');
    const boldItalicText = italicText.replace(/_\*([^*]+)\*_/g, '<strong><em>$1</em></strong>');
    return boldItalicText;
}

const getWsPricing = (_currency, _inrPrice, _usdPrice, _usdSlash, _inrSlash, cutPrice = false) => {
    if (_currency === 'INR') {
        return cutPrice ? `₹${_inrSlash}` : `₹${_inrPrice}`;
    }
    if (_currency === 'USD' && _usdPrice) {
        return cutPrice ? `$${_usdSlash}` : `$${_usdPrice}`;
    } else {
        return cutPrice ? `₹${_inrSlash}` : `₹${_inrPrice}`;
    }
};

const getPrices = (data) => {
    const _INR_PRICE = data?.Batches && data?.Batches?.length > 0 ? data?.Batches[0]?.amount : data?.amount;
    const _USD_PRICE =
        data?.Batches && data?.Batches?.length > 0 && data?.Batches[0]?.dollar
            ? data?.Batches[0]?.dollar
            : data?.dollar;

    const _SLASH_AMOUNT =
        data?.Batches && data?.Batches?.length && data?.Batches[0]?.slashAmount
            ? data?.Batches[0]?.slashAmount
            : data?.slashAmount;

    const _SLASH_DOLLAR =
        data?.Batches && data?.Batches?.length > 0 && data?.Batches[0]?.slashDollar
            ? data?.Batches[0]?.slashDollar
            : data?.slashDollar;

    const _IS_RECORDED = data?.Batches && data?.Batches.length && data?.Batches[0]?.sessionType === 2 ? true : false;

    return { _INR_PRICE, _USD_PRICE, _SLASH_DOLLAR, _SLASH_AMOUNT, _IS_RECORDED };
};

function getSocialMediaPlatform(url) {
    if (url.includes('facebook.')) {
        return 'Facebook';
    } else if (url.includes('twitter.')) {
        return 'Twitter';
    } else if (url.includes('linkedin.')) {
        return 'LinkedIn';
    } else {
        return 'Unknown';
    }
}

function formatNumber(number = 0) {
    return new Intl.NumberFormat('en-US').format(number);
}

const getTriAppNextStep = (user) => {
    if (!user.name || !user.mobile || !user.dob || !user.gender) {
        return 0;
    }
    if (!user.iam || (user.iam && !user.college && user.iam !== 'independent')) {
        return 1;
    }
    if (!user.city) {
        return 2;
    }
    return 3;
};

function extractReelsVideoId(url) {
    // Define a regular expression pattern to match the Reels video URL format
    const regex = /\/reels\/([A-Za-z0-9_-]+)\//;

    // Use the regular expression to extract the ID from the URL
    const match = url.match(regex);

    // Check if a match was found
    if (match && match.length >= 2) {
        // The ID is in the second capturing group (index 1)
        return match[1];
    } else {
        // Return null if no match was found
        return null;
    }
}

const getFileExtension = (filename) => {
    return String(filename).split('.').at(-1);
};


function addNumberSuffix(num) {
    if (num % 100 === 11 || num % 100 === 12 || num % 100 === 13) {
        return num + "th";
    }

    switch (num % 10) {
        case 1:
            return num + "st";
        case 2:
            return num + "nd";
        case 3:
            return num + "rd";
        default:
            return num + "th";
    }
}

export {
    flatDeep,
    slugify,
    containsObject,
    formatPrice,
    getUniqueValues,
    getUniqueValues2,
    getDatesBetween,
    getPaymentUrl,
    getMasterclassUrl,
    getCourseUrl,
    getShortTitle,
    youtube_id_parser,
    getBlogDetailsURL,
    getPricing,
    remove_duplicates,
    replaceLinksWithAnchorTags,
    getInstaPostId,
    getGreeting,
    convertToBoldItalic,
    getWsPricing,
    getPrices,
    getSocialMediaPlatform,
    formatNumber,
    extractReelsVideoId,
    getTriAppNextStep,
    removeDuplicates,
    getFileExtension,
    addNumberSuffix
};
