import axios from 'axios';

axios.defaults.baseURL = process.env.NEXT_PUBLIC_BASE_URL;
// axios.defaults.baseURL = process.env.NODE_ENV === 'development' ? 'http://localhost:8000' : process.env.NEXT_PUBLIC_BASE_URL;

axios.interceptors.response.use(null, (error) => {
    const expectedError = error.response && error.response.status >= 400 && error.response.status < 500;

    if (error.response?.status === 401) {
        // Router.push('/login');
    }
    if (!expectedError) {
        // eslint-disable-next-line no-console
        console.error('Unexpected error: ', error);
    }
    return Promise.reject(error);
});

function setToken(token) {
    axios.defaults.headers.common.Authorization = `Bearer ${token}`;
}

const http = {
    get: axios.get,
    post: axios.post,
    patch: axios.patch,
    put: axios.put,
    delete: axios.delete,
    option: axios,
    setToken,
};

export default http;
