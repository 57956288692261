import React, { PureComponent } from 'react';
import { connect } from 'react-redux';
import { setCurrencyCode } from '../redux/slice/currencySlice';
import { fetchMasterclassesAndCourses } from '../redux/slice/masterclassesAndCoursesSlice';
import { fetchCategoriesData } from '../redux/slice/categoriesSlice';

class Layout extends PureComponent {
  componentDidMount() {
    this.props.dispatch(fetchMasterclassesAndCourses());
    this.props.dispatch(fetchCategoriesData());
    this.props.dispatch(setCurrencyCode());
  }
  render() {
    return <div className='layout'>
      {this.props.children}
    </div>;
  }
}

const mapDispatchToProps = (dispatch) => {
  return {
    dispatch: dispatch
  }
};

export default connect(null, mapDispatchToProps)(Layout);
