import { combineReducers } from '@reduxjs/toolkit';
import coursesReducer from './slice/coursesSlice';
import masterclassesReducer from './slice/masterclassSlice';
import topSellingReducer from './slice/topSellingSlice';
import userReducer from './slice/userSlice';
import categoryReducer from './slice/categoriesSlice';
import searchDataReducer from './slice/searchDataSlice';
import landingPageTripsReducer from './slice/tripsSlice';
import applicationsReducer from './slice/userApplications';
import tripDetailsReducer from './slice/tripDetails';
import blogsReducer from './slice/blogsSlice';
import blogHomepageReducer from './slice/blogHomepageSlice';
import customCourseListReducer from './slice/customCourseList';
import currencyReducer from './slice/currencySlice';
import bannerReducer from './slice/topBannerSlice';
import homepageBlogsReducer from './slice/homepageBlogs';
import dashboardOverviewCoursesReducer from './slice/student-dashboard/course-overview';
import dashboardUserCertificatesReducer from './slice/student-dashboard/certificates';
import dashboardHomeSlice from './slice/student-dashboard/home';
import profilePageTrips from './slice/student-dashboard/trips-list';
import successPageReducer from './slice/successPageSlice';
import dashboardComp from './slice/student-dashboard/dashboardSlice';
import userProfileVer2 from './slice/ver2/user-profile';
import vastuReducer from './slice/vastuSlice';
import courseSearchReducer from './slice/courseSeachSlice';
import masterclassesAndCoursesReducer from './slice/masterclassesAndCoursesSlice';

const kwnDashboardReducers = {
    profilePageTrips: profilePageTrips,
    overviewCourses: dashboardOverviewCoursesReducer,
    userCertificates: dashboardUserCertificatesReducer,
    dashboardHome: dashboardHomeSlice,
    dashboardComp: dashboardComp,
    user: userReducer,
};

const kwnCoursesReducers = {
    courses: coursesReducer,
    masterclasses: masterclassesReducer,
    topSellings: topSellingReducer,
    categories: categoryReducer,
    customCourseList: customCourseListReducer,
    topBanner: bannerReducer,
    courseSearch: courseSearchReducer,
    masterclassesCourses: masterclassesAndCoursesReducer
};

const kwnBlogsReducers = {
    blogs: blogsReducer,
    blogHomeData: blogHomepageReducer,
    homepageBlogs: homepageBlogsReducer,
};

const rootReducer = combineReducers({
    applications: applicationsReducer,
    searchData: searchDataReducer,
    landingPageTrips: landingPageTripsReducer,
    tripDetails: tripDetailsReducer,
    currency: currencyReducer,
    successPage: successPageReducer,
    userVer2: userProfileVer2,
    vastu: vastuReducer,
    ...kwnBlogsReducers,
    ...kwnCoursesReducers,
    ...kwnDashboardReducers,
});
2;

export default rootReducer;
