import http from './httpService';

/**************************************
   Masterclass , Courses & Categories related APIs

   1. Get website header data ->Checked
   2. Get all long-term courses ->Checked
   3. Get all masterclasses ->Checked
   4. Get homepage custom list for masterclasses & courses ->Checked
   5. Get top selling courses for All courses page ->Checked
   6. Get meta data for any course or masterclass (Product page) ->Checked
   7. Get product page data (Matsterclass & Course) ->Checked
   
***************************************/

// Website header data (Masterclass, Long-term courses & Upcoming/Previous trips)
export function getWebsiteHeaderData() {
  return http.get('/api/categories');
}

// Website Header data - Ver2
export function getWebsiteHeaderDataV2() {
  return http.get('/api/v2/categories');
}


// All Long-term courses (Homepage & All courses page)
export function getAllCourses() {
  return http.get('/api/courses');
}

// All Masterclasses (Homepage & All courses page)
export function getAllMasterclasses() {
  return http.get('/api/workshops');
}

// Custom List View for Courses & Masterclasses (Website Homepage)
export function getHomepageCustomList() {
  return http.get('/api/homepage/sections/all');
}

// Top selling Courses (All courses page)
export function getTopSellingCourses() {
  return http.get(`/api/courses/top-selling`);
}

// Get product page meta data (Masterclass & Course page)
export function getWsMetaTags(id) {
  return http.get(`/api/workshop/${id}/tags`);
}

// Get product page details (masterclass & course page)
export function getProductPageDetails(id) {
  return http.get(`/api/workshop/${id}/details`);
}

// Get masterclass / course static landing page data
export function getLandingPageDetails(id) {
  return http.get(`/api/course/${id}/landing-page`);
}

// Get masterclass bonus data
export function getMasterclassBonus(wsId) {
  return http.get(`/api/ws/${wsId}/bonus`);
}


// Get All Courses-And-Workshop page data
export function getAllCoursesPageData() {
  return http.get(`/api/courses-and-workshops`);
}


/*************************************
 *
 *  NEW APIS
 *
***************************************/


// Course/Masterclass Search API
export function getCoursesAndMasterclass() {
  return http.get(`/api/courses/search`);
}

// Get Course/Masterclass Page Hero, Meta &  Bonus Data
export function getProductPageHeroDetails(wsId) {
  return http.get(`/api/workshop/${wsId}/hero-details`)
}


// Get Learners, Curriculums, Instructor Data
export function fetchWorkshopLCIDetails(wsId) {
  return http.get(`/api/workshop/${wsId}/sections/learners-curriculums-instructors`);
}


// Get Outcomes, Bonus Data
export function fetchWorkshopOBDetails(wsId) {
  return http.get(`/api/workshop/${wsId}/sections/outcomes-bonus-testimonials`);
}


// Get Homepage Custom Listing API V2
export function getHomepageCustomListVer2() {
  return http.get(`/api/homepage/sections/all/v2`)
}

// Get All Masterclasses & Courses
export function getMasterclassesAndCourses() {
  return http.get(`/api/all/masterclasses-and-courses`)
}
export function getMasterclassesAndCoursesVer2() {
  return http.get(`/api/all/v2/masterclasses-and-courses`)
}