import React, { useState, useEffect } from 'react';
import { useRouter } from 'next/router';
import { Helmet } from 'react-helmet';
import { Provider } from 'react-redux';
import { GoogleOAuthProvider } from '@react-oauth/google';
import NextTopLoader from 'nextjs-toploader';
import Layout from '../components/Layout';
import store from '../redux/store';
import '../styles/global.scss';
// import ErrorBoundary from '../components/ErrorBoundary';

// import Lenis from 'lenis';

export default function MyApp(props) {
    const { Component, pageProps } = props;
    const router = useRouter();
    const title = 'Kaarwan';
    const [clientId, setClientId] = useState(process.env.NEXT_PUBLIC_GOOGLE_CLIENT_ID);

    let href;
    if (typeof window !== 'undefined') {
        href = window.location.href;
    }

    useEffect(() => {
        const handleRouteChange = () => {
            // pageview(url);
        };
        //When the component is mounted, subscribe to router changes
        //and log those page views
        router.events.on('routeChangeComplete', handleRouteChange);

        // If the component is unmounted, unsubscribe
        // from the event with the `off` method
        return () => {
            router.events.off('routeChangeComplete', handleRouteChange);
        };
    }, [router.events]);

    useEffect(() => {
        if (typeof window !== 'undefined') {
            if (String(href).includes('dev.kaarwan.com')) {
                setClientId(process.env.NEXT_PUBLIC_GOOGLE_CLIENT_DEV_ID);
            } else if (String(href).includes('localhost')) {
                setClientId(process.env.NEXT_PUBLIC_GOOGLE_CLIENT_ID_TEST);
            }
        }
    }, [href]);




    // useEffect(() => {
    //     const lenis = new Lenis()

    //     // lenis.on('scroll', (e) => {
    //     //     console.log(e)
    //     // })

    //     function raf(time) {
    //         lenis.raf(time)
    //         requestAnimationFrame(raf)
    //     }

    //     requestAnimationFrame(raf)
    // }, [])

    return (
        <>
            <Helmet>
                <title>{title}</title>
                <meta name='viewport' content='width=device-width, initial-scale=1' />
                <meta name='theme-color' content='#f59a4c' />
                <link rel='shortcut icon' href='/favicon.ico' type='image/x-icon' />
                <link rel='apple-touch-icon' href='/favicon.ico' />
            </Helmet>
            <GoogleOAuthProvider clientId={clientId}>
                <Provider store={store}>
                    {/* <ErrorBoundary> */}
                    {/* <React.StrictMode> */}
                    <Layout>
                        <Component {...pageProps} />
                        <NextTopLoader color='#e3683b' showSpinner={false} />
                    </Layout>
                    {/* </React.StrictMode> */}
                    {/* </ErrorBoundary> */}
                </Provider>
            </GoogleOAuthProvider>
        </>
    );
}
