import http from './httpService';

/**************************************
    Blogs related APIs

    1.  Get blog detail using blog id ->Checked
    2.  Get blog landing page data ->Checked
    3.  Get all blogs  ->Checked
    4.  Get blogs for Blog Details Page (More Blogs Section) ->Checked
    5.  Get blogs for website homepage  (Explore Section) ->Checked
    
***************************************/

// Get blog details page data (Blog details page)
export function getBlogDetails(id) {
  return http.get(`/api/blog/${id}/details`);
}

// Get blog landing page data (Blog landing page)
export function getBlogHomepageData(id) {
  return http.get(`/api/blog/homepage/${id}/details`);
}

// Get Blog Homepage Hero Section Date (Top Blog, Newest 3, Slider Blogs) 
export function getBlogHeroData() {
  return http.get(`/api/blogs/homepage/hero`)
}


// Get Blog Homepage Trending Topics & Featured Stories Blogs
export function getTrendingAndFeaturedBlogs(){
  return http.get(`/api/blogs/trending-and-featured`)
}

// Get all blogs for categories section on blog landing page (Blog landing page category wise)
export function fetchAllBlogs() {
  return http.get('/api/blog/categories/all');
}

// Get more blogs on blog details page (Blog details page)
export function fetchMoreBlogs() {
  return http.get('/api/blog/more');
}

// Website homepage explore section blogs (Website homepage)
export function getWebHomepageBlogs() {
  return http.get('/api/homepage/blogs');
}

// Blog read count
export function addBlogReadCount(data) {
  return http.post('/api/performance', data);
}

// Vastu Page Blogs
export function getBlogsByIds(ids) {
  return http.post(`https://api.kaarwan.com/api/consultation/page/blogs`, ids);
}

// Fetch All Blogs (Blog Homepage Categories Wise)
export function getAllBlogsVer2(query) {
  return http.get(`/api/blogs/all${query}`);
}