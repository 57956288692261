import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import { getWebsiteHeaderDataV2 } from '../../services/courseServices';

export const fetchCategoriesData = createAsyncThunk('/api/categories', async () => {
    try {
        const resp = await getWebsiteHeaderDataV2();
        const { data } = resp;

        let _data = [];
        let _topSellings = [];
        let _previousTrips = [];
        let _upcomingTrips = [];
        if (data?.code === 200) {
            let _categories = [];

            if (data?.data?.categories && data?.data?.categories.length) {
                _categories = Array.from(data?.data?.categories);
            }
            if (data?.data?.previousTrips && data?.data?.previousTrips.length) {
                _previousTrips = data?.data?.previousTrips;
            }
            if (data?.data?.upcomingTrips && data?.data?.upcomingTrips.length) {
                _upcomingTrips = data?.data?.upcomingTrips;
            }

            if (_categories.length) {
                Array.from(_categories).forEach((cat) => {
                    if (cat?.Subcategory && cat?.Subcategory.length) {
                        let _subCategory = [];

                        Array.from(cat?.Subcategory).forEach((subCat, subCatInd) => {
                            let _workshops = [];

                            _subCategory.push({
                                id: subCat?.id,
                                name: subCat?.name,
                                sequence: subCat?.sequence
                            });

                            if (subCat?.CourseCategories && subCat?.CourseCategories.length) {
                                Array.from(subCat?.CourseCategories).forEach((ws) => {
                                    _workshops.push({
                                        ...ws?.Workshop,
                                        sequence: ws?.sequence
                                    });
                                    if (ws?.Workshop?.markAs) {
                                        _topSellings.push(ws?.Workshop);
                                    }
                                });

                                _subCategory[subCatInd]['Workshops'] = _workshops.sort((a, b) => a?.sequence - b?.sequence);
                            }
                        });


                        _data.push({
                            id: cat?.id,
                            name: cat?.name,
                            Subcategory: Array.from(_subCategory).sort((a, b) => a?.sequence - b?.sequence),
                        });
                    }
                });
            }
        }


        const _finalResult = { courseCategories: _data, previousTrips: _previousTrips, upcomingTrips: _upcomingTrips };

        return {
            categories: _finalResult,
            topSellings: _topSellings,
        };
    } catch (error) {
        console.log('Something went wrong ⚠️', error.message);
        const _finalResult = { courseCategories: [], previousTrips: [], upcomingTrips: [] };
        return {
            categories: _finalResult,
        };
    }
});

const categoriesSlice = createSlice({
    name: 'categories',
    initialState: {
        success: false,
        data: [],
        topSellings: [],
        isFetching: false,
        searchData: [],
    },
    reducers: {
        updateReduxSearchData: (state, action) => {
            state.searchData = action.payload;
        },
    },
    extraReducers: (builder) => {
        builder
            .addCase(fetchCategoriesData.pending, (state) => {
                state.success = false;
                state.isFetching = true;
                state.data = [];
            })
            .addCase(fetchCategoriesData.fulfilled, (state, action) => {
                state.data = action.payload?.categories;
                state.topSellings = action.payload?.topSellings;
                state.isFetching = false;
                state.success = true;
            })
            .addCase(fetchCategoriesData.rejected, (state, action) => {
                state.success = false;
                state.isFetching = false;
                state.data = action.payload?.categories;
            });
    },
});

export const { updateReduxSearchData } = categoriesSlice.actions;

export const selectCategories = (state) => state.categories;

export default categoriesSlice.reducer;
