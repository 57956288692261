import moment from "moment";
import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { getMasterclassesAndCoursesVer2 } from "../../services/courseServices";

export const fetchMasterclassesAndCourses = createAsyncThunk('/api/all/masterclasses-and-courses', async () => {
    try {
        const { data } = await getMasterclassesAndCoursesVer2();
        if (data.data.categories && data.data.categories.length) {
            const _categories = Array.from(data?.data?.categories);

            const _a = [];
            const _m = [];
            const _c = [];
            const _ts = [];

            const currentDate = moment(new Date()).format('YYYY-MM-DD');

            _categories.forEach((cat) => {
                if (cat?.Subcategory && cat?.Subcategory.length) {
                    Array.from(cat?.Subcategory).forEach((subCat) => {
                        if (subCat?.CourseCategories && subCat?.CourseCategories.length) {
                            Array.from(subCat?.CourseCategories).forEach((ws) => {
                                _a.push({
                                    ...ws?.Workshop,
                                    searchKeys: `${ws?.Workshop?.searchKeys || ''}${ws?.Workshop.MetaTags && ws?.Workshop.MetaTags.length ? ws?.Workshop?.MetaTags[0].keywords : ''}`,
                                    Category: {
                                        id: cat?.id,
                                        name: cat?.name,
                                    },
                                });
                                if (ws.Workshop.type === 1) {
                                    _m.push({
                                        ...ws?.Workshop,
                                        Category: {
                                            id: cat?.id,
                                            name: cat?.name,
                                        },
                                    })
                                }
                                if (ws.Workshop.type === 2) {
                                    _c.push({
                                        ...ws?.Workshop,
                                        Category: {
                                            id: cat?.id,
                                            name: cat?.name,
                                        },
                                    })
                                }
                                if (ws.Workshop.markAs) {
                                    _ts.push({
                                        ...ws?.Workshop,
                                        Category: {
                                            id: cat?.id,
                                            name: cat?.name,
                                        },
                                    })
                                }
                            });
                        }
                    });
                }
            });

            const OUTPUT = {
                ALL: _a,
                COURSES: _c,
                MASTERCLASSES: _m,
                TOP_SELLINGS: _ts
            }

            {
                const _upcomingRecent = Array.from(_c).filter(
                    (item) => new Date() <= new Date(item?.Batches[0]?.startDate)
                );

                const _comingSoon = Array.from(_c).filter((item) => item?.Batches[0]?.startDate === '0000-00-00');

                const _expired = Array.from(_c).filter((item) => new Date() > new Date(item?.Batches[0]?.startDate));

                const _finalResult = [
                    ..._upcomingRecent.sort(
                        (a, b) => new Date(a?.Batches[0]?.startDate) - new Date(b?.Batches[0]?.startDate)
                    ),
                    ..._comingSoon,
                    ..._expired.sort((a, b) => new Date(b?.Batches[0]?.startDate) - new Date(a?.Batches[0]?.startDate)),
                ];

                const _allCourses = [];

                _finalResult.map((item) => {
                    if (item?.CouponDetails && item?.CouponDetails.length) {
                        let _coupons = Array.from(item?.CouponDetails);
                        const _noExpiryCoupons = _coupons.filter((item) => item?.Coupon?.endDate === '0000-00-00');
                        const _expiryCoupons = _coupons.filter(
                            (item) => moment(new Date(item?.Coupon?.endDate)) >= new Date(currentDate)
                        );
                        let _allCoupons = [..._noExpiryCoupons, ..._expiryCoupons].map((item) => ({ ...item?.Coupon }));

                        _allCoupons =
                            _allCoupons.length > 1 ? _allCoupons.sort((a, b) => b?.discount - a?.discount) : _allCoupons;

                        _allCourses.push({
                            ...item,
                            CouponDetails: _allCoupons,
                        });
                    } else {
                        _allCourses.push(item);
                    }
                });

                OUTPUT.COURSES = _finalResult;
            }

            {
                let _upcoming = [];
                let _expired = [];
                let _comingSoon = [];

                const recordedCourse = _m.filter(
                    (item) => item?.Batches && item?.Batches.length && item?.Batches[0]?.sessionType === 2
                );
                const nonRecordedCourse = _m.filter(
                    (item) => item?.Batches && item?.Batches.length && item?.Batches[0]?.sessionType === 1
                );

                nonRecordedCourse.forEach((item) => {
                    if (item?.Batches && item?.Batches.length) {
                        const _startDate = item?.Batches[0]?.startDate;

                        if (_startDate === '0000-00-00') {
                            _comingSoon.push(item);
                        }

                        if (_startDate !== '0000-00-00' && new Date(_startDate) < new Date(currentDate)) {
                            _expired.push(item);
                        }

                        if (_startDate !== '0000-00-00' && new Date(_startDate) >= new Date(currentDate)) {
                            _upcoming.push(item);
                        }
                    }
                });

                _expired = _expired.sort((a, b) => new Date(b?.Batches[0]?.startDate) - new Date(a?.Batches[0]?.startDate));
                _upcoming = _upcoming.sort(
                    (a, b) => new Date(a?.Batches[0]?.startDate) - new Date(b?.Batches[0]?.startDate)
                );

                let _finalResult = [..._upcoming, ...recordedCourse, ..._expired, ..._comingSoon];

                const _allMasterclasses = [];

                _finalResult.map((item) => {
                    if (item?.CouponDetails && item?.CouponDetails.length) {
                        let _coupons = Array.from(item?.CouponDetails);
                        const _noExpiryCoupons = _coupons.filter((item) => item?.Coupon?.endDate === '0000-00-00');
                        const _expiryCoupons = _coupons.filter(
                            (item) => moment(new Date(item?.Coupon?.endDate)) >= new Date(currentDate)
                        );
                        let _allCoupons = [..._noExpiryCoupons, ..._expiryCoupons].map((item) => ({ ...item?.Coupon }));

                        _allCoupons =
                            _allCoupons.length > 1 ? _allCoupons.sort((a, b) => b?.discount - a?.discount) : _allCoupons;

                        _allMasterclasses.push({
                            ...item,
                            CouponDetails: _allCoupons,
                        });
                    } else {
                        _allMasterclasses.push(item);
                    }
                });

                OUTPUT.MASTERCLASSES = _allMasterclasses;
            }


            return OUTPUT;

        } else {
            return {
                TOP_SELLINGS: [],
                MASTERCLASSES: [],
                COURSES: [],
                ALL: []
            }
        }
    } catch (error) {
        console.log('Something went wrong ⚠️', error)
        return {
            TOP_SELLINGS: [],
            MASTERCLASSES: [],
            COURSES: [],
            ALL: []
        }
    }
})

const masterclassesCourses = createSlice({
    name: 'masterclassesCourses',
    initialState: {
        courses: {
            TOP_SELLINGS: [],
            MASTERCLASSES: [],
            COURSES: [],
            ALL: [],
        },
        isFetching: false,
        success: false
    },

    extraReducers: (builder) => {
        builder
            .addCase(fetchMasterclassesAndCourses.pending, (state) => {
                state.isFetching = true;
            })
            .addCase(fetchMasterclassesAndCourses.fulfilled, (state, action) => {
                state.isFetching = false;
                state.courses = action.payload;
                state.success = true;
            })
            .addCase(fetchMasterclassesAndCourses.rejected, (state) => {
                state.isFetching = false;
            });
    },
})

export const selectMasterclassesCourses = (state) => state.masterclassesCourses;
export default masterclassesCourses.reducer;
