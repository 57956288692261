import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { getCoursesAndMasterclass } from "../../services/courseServices";

export const fetchCourseSearchList = createAsyncThunk('/api/course/search', async () => {
    try {
        const { data } = await getCoursesAndMasterclass();
        if (data.data && data.data.length) {
            return Array.from(data.data);
        } else {
            return []
        }
    } catch (error) {
        console.log('Something went wrong::>')
    }
})


const courseSearchSlice = createSlice({
    name: 'courseSearch',
    initialState: {
        courses: [],
        isFetching: false,
        success: false
    },
    extraReducers: (builder) => {
        builder
            .addCase(fetchCourseSearchList.pending, (state) => {
                state.isFetching = true;
                state.courses = [];
            })
            .addCase(fetchCourseSearchList.fulfilled, (state, action) => {
                state.isFetching = false;
                if (action.payload && action.payload.length) {
                    state.courses = Array.from(action.payload).map(({ MetaTags, ...rest }) => ({
                        ...rest,
                        keywords: MetaTags && MetaTags.length ? MetaTags[0].keywords : ''
                    }));
                } else {
                    state.courses = [];
                }
                state.success = true;
            })
            .addCase(fetchCourseSearchList.rejected, (state) => {
                state.isFetching = false;
                state.courses = [];
            });
    },
})

export const selectCourseSearch = (state) => state.courseSearch;
export default courseSearchSlice.reducer;
